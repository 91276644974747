import ControllerRaf from '../controllers/controller-raf/script';

export default (bezier, from, to, duration, callback, completeCallback = null) => {
    const startTime = Date.now();
    let timeElapsed = 0;

    const getValue = () => {
        const progressRatio = Math.min(1, timeElapsed / duration);
        const value = (from + this.easingEquation(progressRatio)) * (to - from);
        return value;
    };

    const loop = () => {
        timeElapsed = Date.now() - startTime;
        if (timeElapsed < duration) {
            callback(getValue());
        } else {
            callback(getValue());
            if (typeof completeCallback === 'function') {
                completeCallback(getValue());
            }
            ControllerRaf.off(loop);
        }
    };

    import('bezier-easing').then(({ default: BezierEasing }) => {
        this.easingEquation = new BezierEasing(...bezier);
        ControllerRaf.on(loop);
    });
};
